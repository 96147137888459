import { CmsClient } from '.'
import { getNoticeList } from 'shared/notice'
import { Faq } from 'types/faq'
import { News } from 'types/news'

const MAX_LIST_SIZE = 10 as const

export class localCmsClient implements CmsClient {
  public fetchNewses(page?: number): Promise<{ items: News[]; total: number }> {
    const offset = ((page || 1) - 1) * MAX_LIST_SIZE
    return new Promise((resolve) => {
      const result = getNoticeList()
      const newses = result
        .sort((a, b) => (new Date(a.publishedAt || '') > new Date(b.publishedAt || '') ? -1 : 1))
        .slice(offset, offset + MAX_LIST_SIZE)

      const items = newses.map((news) => ({
        id: news.id,
        title: news.title,
        content: news.content,
        publishedAt: news.publishedAt || '',
        category: {
          name: news.category.name,
          color: news.category.color,
        },
      }))

      return resolve({ items, total: result.length })
    })
  }

  public fetchNews(id: string): Promise<News> {
    return new Promise((resolve) => {
      const notices = getNoticeList()
      const notice = notices.find((n) => n.id === id)

      if (!notice) {
        throw new Error('not found news')
      }

      return resolve(notice)
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public fetchFaqs(page?: number | undefined): Promise<{ items: Faq[]; total: number }> {
    throw new Error('Method not implemented.')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public fetchFaq(id: string): Promise<Faq> {
    throw new Error('Method not implemented.')
  }
}
